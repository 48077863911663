
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { v4 } from "uuid"
import api from "../../api"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise.svg"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg"
import { Each } from "../../common/Each"
import { FeedType } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { calcLastUpdate, capitalize, formatDateV2, formatTimeV2, getLocalDate } from "../../utils"
import Button from "../Button"
import Loader from "../Loader"
import MaterialButton from "../MaterialButton"
import SimpleCollapsable from "../SimpleCollapsable"
import Card from "./Card"
import styles from "./ExerciseFeedCard.module.css"
import ExerciseMaterialCard from "./ExerciseMaterialCard"

const ExerciseStatus = {
  toBeDone: 0,
  Submitted: 1,
  Expired: 2,
}

const ExerciseFeedCard = ({ activity = {}, teacher, onSubmit = () => { } }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [files, setFiles] = useState([])
  const [exerciseLoading, setExerciseLoading] = useState(false)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState(null)
  const navigate = useNavigate();
  const dropzoneRef = useRef()
  const expandableRef = useRef(null)

  useEffect(() => {
    if (!expandableRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const { current } = expandableRef
      const boundingRect = current.getBoundingClientRect()
      const { height } = boundingRect
      setHeight(Math.round(height))
    });
    resizeObserver.observe(expandableRef.current);
    return () => resizeObserver.disconnect();
  }, [expandableRef])

  const exerciseMedias = useMemo(() => {
    if (!activity || activity.type !== FeedType.Exercise) {
      return []
    }
    return activity.object.medias ? activity.object.medias : []
  }, [activity])

  const userExerciseMedias = useMemo(() => {
    if (!activity?.object?.user_exercise?.medias) {
      return []
    }
    return activity.object.user_exercise.medias
  }, [activity])

  const exerciseStatus = useMemo(() => {
    console.debug({ exercise: activity.object })
    if (activity.object.user_exercise) {
      return ExerciseStatus.Submitted
    }

    const { expires_at } = activity.object
    const localeExpirationDate = getLocalDate(expires_at)
    const now = new Date()
    if (!expires_at || (expires_at && localeExpirationDate >= now)) {
      return ExerciseStatus.toBeDone
    } else if (expires_at && localeExpirationDate < now) {
      return ExerciseStatus.Expired
    }
  }, [activity])

  const correction = useMemo(() => {
    if (!activity?.object?.user_exercise?.correction) {
      return null
    }

    return activity.object.user_exercise.correction
  }, [activity])


  const submitExercise = useCallback(async () => {
    setExerciseLoading(true)
    setError(null)
    try {
      const id = activity.object_id

      const formData = new FormData()

      for (const file of files) {
        formData.append("files", file)
      }

      await api.post(`/exercises/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      onSubmit()
    } catch (e) {
      console.error(e)
      setError(e.detail)
    }
    setExerciseLoading(false)

  }, [activity, files])

  return (
    <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
      <div className={styles.cardHeader}>
        <div className={`${styles.icon} ${styles.exercise}`}>
          <ExerciseIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {activity.object.name}

          </div>
          <div className={styles.subtitle} style={{
            color: exerciseStatus === ExerciseStatus.Expired ? "var(--secondary)" : "inherit"
          }}>
            {
              activity.object.expires_at &&
              <>
                {width > 540 &&
                  <>
                    Entro le {formatTimeV2(activity.object.expires_at)} di {capitalize(formatDateV2(activity.object.expires_at, { weekday: 'long', day: 'numeric', month: 'long' }))}
                  </>
                }
                {width < 540 &&
                  <>
                    Entro {formatDateV2(activity.object.expires_at, { weekday: 'short', day: 'numeric', month: 'short' }).toUpperCase()}, {formatTimeV2(activity.object.expires_at)}
                  </>
                }
              </>
            }
          </div>
        </div>
        <div className={styles.info} style={{ alignItems: "flex-end", gap: ".2rem" }}>
          {
            exerciseStatus === ExerciseStatus.Expired &&
            <div className={styles.statusBadge} style={{ "--color": "var(--secondary)" }}>{t("expired").toUpperCase()}</div>
          }
          <div className={styles.date}>
            {activity.profile.name} {activity.profile.surname}, {calcLastUpdate(activity.created_at, width > 768 ? false : true)}
            <ClockIcon className={styles.clockIcon} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.description} style={{ padding: 0 }}>
          {activity.object.description}
        </div>
        {
          exerciseMedias.length > 0 &&
          <div className={styles.filesContainer}>
            <div className={styles.files}>
              <Each
                of={exerciseMedias}
                render={(file) => (
                  <div className={styles.file}>
                    <MaterialButton
                      material={file}
                      onClick={() => {
                        setFiles((prevFiles) => {
                          const idx = prevFiles.findIndex(f => f.id === file.id)
                          if (idx > -1) prevFiles.splice(idx, 1)
                          return [...prevFiles]
                        })
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        }
        {
          exerciseStatus === ExerciseStatus.Expired &&
          <>
            <div style={{
              display: files.length === 0 && !exerciseLoading ? "flex" : "none",
              flexDirection: "column",
              paddingTop: "1rem"
            }}>
              <div className={styles.helperText}>{t("exercises.helperText")}</div>
              <Dropzone
                disabled
                onDrop={() => { }}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                    <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                      <input {...getInputProps()} />
                      <div className={styles.dropzone}
                        style={{
                          background: "var(--background-secondary-color)",
                          border: "2px dashed #dedede"
                        }}
                      >
                        <InsertDocumentIllustration style={{ filter: "grayscale(1)" }} />
                        <div className={styles.dropzoneLabel}>
                          {t("dragAndDrop")}
                        </div>
                        <Button
                          style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                          accentColor={"var(--tertiary)"}
                          disabled
                        >
                          {t("selectFile").toUpperCase()}
                        </Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className={styles.submit}>
              {error && <div className={styles.error}>{error}</div>}
              <Button
                disabled
                style={{ padding: '0.6rem 2rem' }}
                accentColor={"var(--tertiary)"}
              >
                {t("feed.submit").toUpperCase()}
              </Button>
            </div>
          </>
        }
        {
          exerciseStatus === ExerciseStatus.toBeDone &&
          <>
            {
              files.length > 0 && !exerciseLoading &&
              <>
                <div className={styles.addFile} >
                  {t("feed.attachment")}
                  <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                    if (dropzoneRef && dropzoneRef.current) {
                      dropzoneRef.current.click();
                    }
                  }} />
                </div>
                <div className={styles.filesContainer}>
                  <div className={styles.files}>
                    <Each
                      of={files}
                      render={(file) => (
                        <div className={styles.file}>
                          <MaterialButton
                            downloadable={false}
                            material={file}
                            onClick={() => {
                              setFiles((prevFiles) => {
                                const idx = prevFiles.findIndex(f => f.id === file.id)
                                if (idx > -1) prevFiles.splice(idx, 1)
                                return [...prevFiles]
                              })
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </>
            }
            {
              files.length > 0 && exerciseLoading &&
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            }
            <div style={{
              display: files.length === 0 && !exerciseLoading ? "flex" : "none",
              flexDirection: "column",
              paddingTop: "1rem"
            }}>
              <div className={styles.helperText}>{t("exercises.helperText")}</div>
              <Dropzone
                onDrop={(newFiles) => {
                  newFiles.forEach(f => f.id = v4())
                  setFiles([...files, ...newFiles])
                }}>
                {({ getRootProps, getInputProps }) => (
                  <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                    <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                      <input {...getInputProps()} />
                      <div className={styles.dropzone}>
                        <InsertDocumentIllustration />
                        <div className={styles.dropzoneLabel}>
                          {t("dragAndDrop")}
                        </div>
                        <Button
                          style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                          onClick={getInputProps.onClick}
                          accentColor={"var(--tertiary)"}
                        >
                          {t("selectFile").toUpperCase()}
                        </Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className={styles.submit}>
              {error && <div className={styles.error}>{error}</div>}
              <Button
                disabled={exerciseLoading || files.length === 0}
                style={{ padding: '0.6rem 2rem' }}
                onClick={submitExercise}
                accentColor={"var(--tertiary)"}
              >
                {t("feed.submit").toUpperCase()}
              </Button>
            </div>
          </>
        }
        {
          exerciseStatus === ExerciseStatus.Submitted &&
          <div className={styles.submitsContainer}>
            <div className={styles.helperText}>{t("exercises.helperTextSubmitted")}</div>
            <Each
              of={userExerciseMedias}
              render={(material) => (
                <ExerciseMaterialCard exerciseId={activity.object_id} material={material} onSubmit={onSubmit} />
              )}
            />
          </div>
        }

        {
          exerciseStatus === ExerciseStatus.Submitted && correction &&
          <div className={styles.commentContainer}>
            <div className={styles.commentHeader} style={{
              borderRadius: !open ? "12px" : "12px 12px 0 0 "
            }}>
              <div className={styles.teacher}>
                <img
                  className={styles.teacherPicture}
                  src={teacher.picture}
                  alt=""
                />
                <div className={styles.teacherName}><b>{teacher.name} {teacher.surname}</b> {t("commented")}</div>
              </div>
              <div className={styles.time}>
                {formatTimeV2(parseInt(correction.created_at * 1000), null, "it", false)}, {formatDateV2(parseInt(correction.created_at * 1000), null, "it", false)} <ClockIcon className={styles.clock} />
                <ChevronIcon className={`${styles.arrow} ${open ? styles.up : styles.down}`} onClick={() => setOpen(o => !o)} />
              </div>
            </div>
            <SimpleCollapsable
              expanded={open}
              expadendHeight={`${height}px`}
            >
              <div ref={expandableRef} className={styles.extended}>
                <div className={styles.comment}>
                  {correction.message}
                </div>
                {
                  correction.medias && correction.medias.length > 0 &&
                  <div className={styles.filesContainer}>
                    <div className={styles.files}>
                      <Each
                        of={correction.medias}
                        render={(file) => (
                          <div className={styles.file}>
                            <MaterialButton
                              style={{ backgroundColor: "var(--background-color)" }}
                              material={file}
                              downloadable
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                }
              </div>
            </SimpleCollapsable>
          </div>
        }

      </div>
    </Card >
  )
}

export default ExerciseFeedCard
