import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import api from "../api"
import { CourseStatus } from "../common/constants"
import CourseSubscribe from "../components/CourseSubscribe"
import Loader from "../components/Loader"
import MasterclassFeed from "../components/MasterclassFeed"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Course.module.css"

const Course = () => {

    const { slug } = useParams()
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getCourseStatus = async (slug) => {
            try {
                setLoading(true)
                let course = await api.get(`/courses/${slug}/status`)
                setStatus(course.status)
                setLoading(false)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (slug) {
            getCourseStatus(slug)
        }
    }, [slug])

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    return (
        <HeaderFooterLayout>
            {!loading &&
                <>
                    {status === CourseStatus.ToStart &&
                        <CourseSubscribe slug={slug}></CourseSubscribe>
                    }
                    {status !== CourseStatus.ToStart &&
                        <MasterclassFeed slug={slug}></MasterclassFeed>
                    }
                </>
            }
            {loading &&
                <div className={styles.container}>
                    <Loader />
                </div>
            }
        </HeaderFooterLayout>
    )

}

export default Course