import { useTranslation } from "react-i18next"
import styles from "./TrueFalseBadge.module.css"

const TrueFalseBadge = ({ type = false, value = null, onChange = null }) => {
  const { t } = useTranslation()

  return (
    <>
      {
        !type &&
        <div
          style={{ cursor: onChange ? "pointer" : "default" }}
          onClick={() => {
            if (!onChange) {
              return
            }

            if (value === false) {
              onChange(null)
            } else {
              onChange(false)
            }
          }}
          className={value === false ? `${styles.container} ${styles.false} ${styles.checked}` : `${styles.container} ${styles.false}`}>
          {t("false").toUpperCase()}
        </div>
      }
      {
        type &&
        <div
          style={{ cursor: onChange ? "pointer" : "default" }}
          onClick={() => {
            if (!onChange) {
              return
            }

            if (value === true) {
              onChange(null)
            } else {
              onChange(true)
            }
          }}
          className={value ? `${styles.container} ${styles.true} ${styles.checked}` : `${styles.container} ${styles.true}`}>
          {t("true").toUpperCase()}
        </div>
      }
    </>
  )
}


export default TrueFalseBadge
