import { Splide, SplideSlide } from "@splidejs/react-splide"
import confetti from 'canvas-confetti'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import api from "../api"
import municipalitiesData from "../assets/comuni.json"
import { ReactComponent as WorkIcon } from "../assets/images/icons/ic-bag.svg"
import { ReactComponent as LogoutIcon } from "../assets/images/icons/ic-logout.svg"
import { ReactComponent as SchoolIcon } from "../assets/images/icons/ic-school.svg"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as BullIllustration } from "../assets/images/illustrations/il-bull.svg"
import { SurveyType } from "../common/constants.js"
import { Each } from "../common/Each.js"
import MainContext from "../common/MainContext.js"
import Progress from "../components/animated/Progress.js"
import StepHighlight from "../components/animated/StepHighlight.js"
import Button from "../components/Button.js"
import Card from "../components/cards/Card.js"
import DropdownSelection from "../components/DropdownSelection.js"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import TextInput from "../components/TextInput.js"
import { auth } from "../firebase"
import { findMostFrequent } from "../utils.js"
import styles from "./Survey.module.css"

let delayTimeout = null
let hideTimeout = null

const Survey = () => {
  const context = useContext(MainContext)
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [survey, setSurvey] = useState(null)
  const [groups, setGroups] = useState([])
  const [userAnswers, setUserAnswers] = useState([])
  const [hideConfetti, setHideConfetti] = useState(false)

  // inputs
  const [textareaValue, setTextareaValue] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [age, setAge] = useState("")
  const [city, setCity] = useState(null)
  const [defaultCity, setDefaultCity] = useState(null)
  const [gender, setGender] = useState(null)
  const [employment, setEmployment] = useState(null)

  // navigation
  const [index, setIndex] = useState(0)
  const [groupIndex, setGroupIndex] = useState(0)
  const [page, setPage] = useState(0)
  const [lastId, setLastId] = useState(null)
  const [end, setEnd] = useState(false)
  const [canContinue, setCanContinue] = useState(false)

  const splideGroupRef = useRef(null)
  const splideRefs = useRef([])
  const confettiRef = useRef(null)

  const municipalities = useMemo(() => {
    return municipalitiesData.map((m) => {
      return {
        id: m.codice,
        label: `${m.nome} (${m.sigla})`
      }
    })
  }, [])

  const genders = useMemo(() => {
    return [
      { label: t("survey.female"), value: "female" },
      { label: t("survey.male"), value: "male" },
      { label: t("survey.other"), value: "other" },
    ]
  }, [])

  const employments = useMemo(() => {
    return [
      { label: t("survey.employments.student"), value: "student", icon: SchoolIcon },
      { label: t("survey.employments.worker"), value: "worker", icon: WorkIcon },
      { label: t("survey.employments.studentWorker"), value: "studentWorker" },
      { label: t("survey.employments.neither"), value: "neither" },
    ]
  }, [])

  useEffect(() => {
    if (!survey) {
      return
    }

    const { content } = survey
    const groups = {}
    for (const s of content) {
      const { group } = s
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(s)
    }
    const g = Object.values(groups)
    setGroups(g)
  }, [survey])

  useEffect(() => {
    if (context?.user) {
      setName(context.user.name)
      setSurname(context.user.surname)
      setAge(context.user.age ?? null)
      setGender(context.user.gender ?? null)
      setEmployment(context.user.employment ?? null)

      if (context.user.city) {
        const city = municipalities.find(m => m.label === context.user.city)
        if (city?.id) {
          setDefaultCity(city.id)
        }
      }
    }

  }, [context])

  useEffect(() => {

    const getSurvey = async () => {
      try {
        const survey = await api.get("/survey")
        setSurvey(survey)
      } catch (e) {
        console.error(e)
      }
    }

    getSurvey()

    return () => {
      if (delayTimeout) {
        clearTimeout(delayTimeout)
        delayTimeout = null
      }

      if (hideTimeout) {
        clearTimeout(hideTimeout)
        hideTimeout = null
      }
    }
  }, [])

  useEffect(() => {
    if (splideGroupRef && splideGroupRef.current) {
      splideGroupRef.current.splide.go(page)
    }
  }, [page])

  useEffect(() => {
    if (splideRefs && splideRefs.current.length > 0) {
      splideRefs.current[groupIndex].splide.go(index)
    }
  }, [index, groupIndex])

  useEffect(() => {
    if (!end) {
      return
    }

    var onboarding = { ...context.onboarding }
    for (let step of onboarding.steps) {
      if (step.type === 'survey') {
        step.actions[0].completed = true
        step.completed = true
      }
    }

    context.setOnboarding({ ...onboarding })

    if (splideGroupRef && splideGroupRef.current) {
      splideGroupRef.current.splide.go(groups.length + 1)
    }

    const myCanvas = document.createElement('canvas');
    if (confettiRef && confettiRef.current) {
      confettiRef.current.appendChild(myCanvas);

    }

    const myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });
    const count = 200;
    const defaults = {
      origin: { y: 0.38, decay: 0.2 }
    };

    function fire(particleRatio, opts) {
      myConfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    delayTimeout = setTimeout(() => {
      fire(0.25, {
        spread: 70,
        startVelocity: 55,
        decay: 0.85

      });
      fire(0.2, {
        spread: 200,
        decay: 0.8

      });
      fire(0.35, {
        spread: 100,
        decay: 0.7,
        scalar: 0.9
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.8,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        decay: 0.8
      });

      delayTimeout = null
    }, 500)

    hideTimeout = setTimeout(() => {
      setHideConfetti(true)
      hideTimeout = null
    }, 4000)
  }, [end])

  useEffect(() => {
    if (page === 0) {

      setCanContinue(
        !!name &&
        !!surname &&
        !isNaN(age) &&
        !!city &&
        !!gender &&
        !!employment
      )
    }
  }, [age, city, gender, employment, page, name, surname])

  const endSurvey = useCallback(async () => {
    try {
      await api.post(`/survey/${survey.id}`, {
        content: { content: userAnswers }
      })
    } catch (e) {
      console.error(e)
    }
  }, [userAnswers, survey])


  const onSingleChoiceClick = useCallback((optionIndex) => {
    const id = groups[groupIndex][index].id
    const newAnswer = {
      id,
      type: SurveyType.SingleChoice,
      text: groups[groupIndex][index].text,
      answer: groups[groupIndex][index].options[optionIndex],
      answerId: optionIndex,
      previous: lastId
    }

    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)
    if (userAnswersIndex === -1) {
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
    } else {
      if (userAnswers[userAnswersIndex].answerId === optionIndex) {
        setUserAnswers(userAnswers.slice(0, userAnswersIndex))
      } else {
        userAnswers[userAnswersIndex] = newAnswer
        setUserAnswers([...userAnswers])
      }

    }

    setCanContinue(true)
  }, [userAnswers, groups, groupIndex, index, lastId])

  const onMultipleChoiceClick = useCallback((optionIndex) => {
    const id = groups[groupIndex][index].id
    const newAnswer = {
      id,
      type: SurveyType.MultipleChoice,
      text: groups[groupIndex][index].text,
      answers: [{ id: optionIndex, ...groups[groupIndex][index].options[optionIndex] },],
      previous: lastId
    }
    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)
    if (userAnswersIndex === -1) {
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
    } else {
      if (userAnswers[userAnswersIndex].answers.map(a => a.id).includes(optionIndex)) {
        if (userAnswers[userAnswersIndex].answers.length > 1) {
          // remove ids from list but keep other answer selected
          userAnswers[userAnswersIndex].answers.splice(
            userAnswers[userAnswersIndex].answers.findIndex(a => a.id === optionIndex),
            1
          )
          setUserAnswers([...userAnswers])
        } else {
          // remove successive answers
          setUserAnswers(userAnswers.slice(0, userAnswersIndex))
        }

      } else {
        userAnswers[userAnswersIndex].answers.push({ id: optionIndex, ...groups[groupIndex][index].options[optionIndex] })
        setUserAnswers([...userAnswers])
      }
    }

    setCanContinue(true)
  }, [userAnswers, groups, groupIndex, index, lastId])

  const goBack = useCallback(() => {
    if (page === 1 && index === 0) {
      setPage(0)
      setUserAnswers([])
      return
    }

    if (lastId) {
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < groups[i].length; j++) {
          if (groups[i][j].id === lastId) {
            setPage(i + 1)
            setGroupIndex(i)
            setIndex(j)
            setCanContinue(true)
            const answerIdx = userAnswers.findIndex(ua => ua.id === groups[i][j].id)
            if (answerIdx !== -1) {
              setLastId(userAnswers[answerIdx].previous)
            }
            setTextareaValue("")
            setUserAnswers(userAnswers.slice(0, answerIdx + 1))
            return
          }
        }
      }
    }
  }, [lastId, groups, index, userAnswers, page])

  const goForward = useCallback(async () => {
    if (page === 0) {
      setPage(1)
      setCanContinue(false)
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("surname", surname);
        formData.append("age", age);
        formData.append("employment", employment);
        formData.append("gender", gender);
        formData.append("city", city.label);
        const user = await api.put("/user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        if (user) {
          context.setUser(user)
        }
      } catch (e) {
        console.error(e)
      }
      return
    }

    const id = groups[groupIndex][index].id
    const currentAnswer = userAnswers.find(ua => ua.id === id)
    const { type } = currentAnswer
    setCanContinue(false)

    let targetId = null
    switch (type) {
      case SurveyType.SingleChoice:
        targetId = currentAnswer.answer.next
        break
      case SurveyType.MultipleChoice:
        targetId = parseInt(findMostFrequent(currentAnswer.answers.map(a => a.next)))
        break
      case SurveyType.Open:
        targetId = currentAnswer.next
        break
      default:
        console.error("Unsupported Survey type")
        break
    }

    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].length; j++) {
        if (groups[i][j].id === targetId) {
          setPage(i + 1)
          setGroupIndex(i)
          setIndex(j)
          setLastId(id)

          const answer = userAnswers.find(ua => ua.id === groups[i][j].id)
          if (answer) {
            setCanContinue(true)
          }
          return
        }
      }
    }

    setPage(groups.length + 1)
    setEnd(true)
    endSurvey()
  }, [groups, userAnswers, groupIndex, index, page, endSurvey, age, name, surname, employment, city, gender, context])

  const onOpenChange = useCallback((e) => {
    const { value } = e.target
    setTextareaValue(value)
    setCanContinue(!!value)

    const id = groups[groupIndex][index].id
    const newAnswer = {
      id,
      type: SurveyType.Open,
      text: groups[groupIndex][index].text,
      value,
      previous: lastId,
      next: groups[groupIndex][index].next
    }
    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)

    if (userAnswersIndex !== -1) {
      userAnswers.pop()
    }
    userAnswers.push(newAnswer)
  }, [userAnswers, groups, lastId, groupIndex, index])

  return (
    <HeaderFooterLayout hideFooter>
      <HelmetProvider>
        <Helmet>
          <title>Questionario</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        {
          groups && groups.length > 0 &&
          <div className={styles.survey}>
            <div className={styles.top}>
              <div className={styles.groupProgress}>
                <Progress nSteps={1 + groups.length} nCompleted={page} />
              </div>
              <div className={styles.slideContainer}>
                <Splide aria-label="..." ref={splideGroupRef} options={{
                  rewind: false,
                  drag: false,
                  autoplay: false,
                  pagination: false,
                  height: "100%",
                }}>
                  <SplideSlide>
                    {
                      page === 0 &&
                      <div className={styles.registration}>
                        <div className={styles.question}>
                          {t("survey.completeRegistration")}
                        </div>
                        <div className={styles.registrationInputs}>
                          <div className={styles.registrationSection}>
                            <TextInput
                              type={"text"}
                              value={name}
                              placeholder={t("survey.name")}
                              onKeyUp={(value) => {
                                setName(value)
                              }} style={{ minHeight: "60px", minWidth: "100%" }} />
                            <TextInput
                              type={"text"}
                              value={surname}
                              placeholder={t("survey.surname")}
                              onKeyUp={(value) => {
                                setSurname(value)
                              }} style={{ minHeight: "60px", minWidth: "100%" }} />
                            <TextInput
                              type={"number"}
                              value={age}
                              placeholder={t("survey.age")}
                              onKeyUp={(value) => {
                                setAge(value)
                              }} style={{ minHeight: "60px", minWidth: "100%" }} />
                            <DropdownSelection
                              search
                              required
                              placeholder={t("survey.municipality")}
                              options={municipalities}
                              style={{ width: "100%" }}
                              defaultOption={defaultCity}
                              onSelect={(id) => {
                                if (id) {
                                  const city = municipalities.find(m => m.id === id)
                                  if (city) {
                                    setCity(city)
                                  }
                                } else {
                                  setCity(null)
                                }
                              }} />
                          </div>
                          <div className={styles.registrationSection}>
                            <div className={styles.registrationTitle}>{t("survey.gender")}</div>
                            <div className={styles.genders}>
                              <Each
                                of={genders}
                                render={(g) => (
                                  <div
                                    className={gender === g.value ? `${styles.box} ${styles.selected}` : styles.box}
                                    onClick={() => {
                                      if (g.value === gender) {
                                        setGender(null)
                                      } else {
                                        setGender(g.value)
                                      }
                                    }}
                                  >
                                    {g.label}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className={styles.registrationSection}>
                            <div className={styles.registrationTitle}>{t("survey.employment")}</div>
                            <div className={styles.employments}>
                              <Each
                                of={employments}
                                render={(e) => (
                                  <div
                                    className={employment === e.value ? `${styles.box} ${styles.selected}` : styles.box}
                                    onClick={() => {
                                      if (e.value === employment) {
                                        setEmployment(null)
                                      } else {
                                        setEmployment(e.value)
                                      }
                                    }}
                                  >
                                    {e.icon && <e.icon />}
                                    {e.label}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </SplideSlide>
                  <Each
                    of={groups}
                    render={(group, i) => (
                      <SplideSlide>
                        <div className={styles.groupContainer}>
                          <div className={styles.groupName}>
                            {group[0].group.toUpperCase()}
                          </div>
                          <div className={styles.innerSplideContainer}>
                            <Splide aria-label="..." ref={(element) => splideRefs.current.push(element)} options={{
                              rewind: false,
                              drag: false,
                              autoplay: false,
                              width: "100%",
                              pagination: false
                            }}>
                              <Each
                                of={group}
                                render={(surveyQuestion, j) => (
                                  <SplideSlide>
                                    {
                                      page === (i + 1) && index === j &&
                                      <div className={styles.questionContainer}>
                                        <div className={styles.question}>{surveyQuestion.text}</div>
                                        {
                                          surveyQuestion.type === SurveyType.SingleChoice &&
                                          <div className={styles.options}
                                            style={{
                                              flexDirection: surveyQuestion.options.length > 2 ? "column" : "row"
                                            }}
                                          >
                                            <Each
                                              of={surveyQuestion.options}
                                              render={(option, optionIdx) => {
                                                const userAnswer = userAnswers.find(ua => ua.id === surveyQuestion.id)
                                                let className = styles.option
                                                if (userAnswer) {
                                                  if (userAnswer.answerId === optionIdx) {
                                                    className = `${styles.option} ${styles.selected}`
                                                  } else {
                                                    className = `${styles.option} ${styles.discarded}`
                                                  }
                                                }
                                                return (
                                                  <div
                                                    className={className}
                                                    onClick={() => onSingleChoiceClick(optionIdx)}>
                                                    {option.value}
                                                  </div>
                                                )
                                              }}
                                            />
                                          </div>
                                        }

                                        {
                                          surveyQuestion.type === SurveyType.MultipleChoice &&
                                          <div className={styles.options}
                                            style={{
                                              flexDirection: surveyQuestion.options.length > 2 ? "column" : "row"
                                            }}
                                          >
                                            <Each
                                              of={surveyQuestion.options}
                                              render={(option, optionIdx) => {
                                                const userAnswer = userAnswers.find(ua => ua.id === surveyQuestion.id)
                                                let className = styles.option
                                                if (userAnswer && userAnswer.answers.map(a => a.id).includes(optionIdx)) {
                                                  className = `${styles.option} ${styles.selected}`
                                                }
                                                return (
                                                  <div
                                                    className={className}
                                                    onClick={() => onMultipleChoiceClick(optionIdx)}>
                                                    {option.value}
                                                  </div>
                                                )
                                              }}
                                            />
                                          </div>
                                        }

                                        {
                                          surveyQuestion.type === SurveyType.Open &&
                                          <div className={styles.options}>
                                            <div className={styles.openContainer}>
                                              <div className={styles.helperText}>
                                                {t("typeHere")}
                                              </div>
                                              <Card hover style={{ padding: ".5rem 1rem", width: '100%' }} title={t("tests.description")}>
                                                <div className={styles.textAreaContainer}>
                                                  <TextareaAutosize
                                                    value={textareaValue}
                                                    minRows={3} maxRows={15}
                                                    type="text"
                                                    className={styles.textArea}
                                                    onChange={onOpenChange} />
                                                </div>
                                              </Card>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    }
                                  </SplideSlide>
                                )}
                              />
                            </Splide>
                          </div>
                        </div>
                      </SplideSlide>
                    )}
                  />
                  <SplideSlide>
                    <div className={styles.groupContainer}>
                      <div className={styles.question}>
                        {t("survey.thanks")}
                      </div>
                      <div>
                        {t("survey.message")}
                      </div>
                      <div className={styles.bull}>
                        {!hideConfetti && <div className={styles.confetti} ref={confettiRef} />}
                        <BullIllustration />
                      </div>
                      <Button
                        style={{ marginTop: "1rem" }}
                        accentColor={"var(--tertiary)"}
                        onClick={() => {
                          if (location.pathname !== '/survey') {
                            window.location.reload(false)
                          }
                          else {
                            navigate("/")
                          }
                        }}
                      >
                        {t("explore").toUpperCase()}
                      </Button>
                      <div className={styles.spacer} />
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
            {
              end === false &&
              <div className={styles.pagination}>
                {
                  page > 0 &&
                  <StepHighlight nSteps={groups[groupIndex].length} currentStep={
                    userAnswers.findIndex(ua => ua.id === groups[groupIndex][index].id) === -1 ? index : index + 1
                  } />
                }
                <div className={styles.pageButtons}
                  style={{
                    borderTop: page === 0 ? "1px solid rgba(var(--text-color-rgb), 12%)" : "none"
                  }}
                >
                  {
                    page === 0 && index === 0 &&
                    <>
                      {location.pathname !== '/survey' &&
                        <Button
                          inverse
                          additionalClass={styles.actionButton}
                          onClick={async () => {
                            await auth.signOut()
                            context.setUser(null)
                            context.setCart(null)
                            window.location.reload(false)
                          }}
                          accentColor={"var(--secondary)"}
                        >
                          <LogoutIcon />
                          {t("exit").toUpperCase()}
                        </Button>
                      }
                      {location.pathname === '/survey' &&
                        <div style={{ width: '100%' }} />
                      }
                    </>

                  }
                  {
                    page > 0 &&
                    <Button
                      style={{ padding: ".5rem 4rem" }}
                      inverse
                      disabled={page === 0 && index === 0}
                      onClick={goBack}
                    >
                      <ArrowIcon />
                      {t("back").toUpperCase()}
                    </Button>
                  }
                  <Button
                    // additionalClass={styles.actionButton}
                    style={{ padding: ".5rem 4rem" }}
                    disabled={canContinue === false}
                    onClick={goForward}>
                    {t("continue").toUpperCase()}
                  </Button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </HeaderFooterLayout >
  )
}


export default Survey
