import { DailyAudio, DailyVideo, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import styles from "./DailyParticipant.module.css";

const DailyParticipant = ({ session_id, isLocal = false, style, onClick = () => { } }) => {

    const [participant, setParticipant] = useState(null)
    const participantProprerty = useParticipantProperty(session_id, ['audio', 'video', 'userData', 'tracks', 'user_name', 'owner'])
    const { screens } = useScreenShare()
    const streamRef = useRef(null)

    useEffect(() => {
        if (participantProprerty) {
            let participant = {
                id: session_id,
                name: participantProprerty[4],
                owner: participantProprerty[5],
                audio: participantProprerty[0],
                video: participantProprerty[1],
                data: participantProprerty[2],
                whiteboard: participantProprerty?.[3]?.whiteboard ?? null,
                presentation: participantProprerty?.[3]?.presentation ?? null,
                isLocal: isLocal
            }
            setParticipant(participant)
        }
    }, [participantProprerty])

    useEffect(() => {
        if (participant && streamRef && streamRef.current) {
            if (participant.whiteboard && participant.whiteboard.state === 'playable') {
                const stream = new MediaStream([participant.whiteboard.track])
                streamRef.current.srcObject = stream
            }
            if (participant.presentation && participant.presentation.state === 'playable') {
                const stream = new MediaStream([participant.presentation.track])
                streamRef.current.srcObject = stream
            }
        }
    }, [participant, streamRef])

    return (
        <>
            {participant &&
                <div className={styles.container} style={style} onClick={() => {
                    onClick(false, false, false)
                }}>
                    {participant.video &&
                        <DailyVideo automirror={participant.isLocal} sessionId={session_id} style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                    }
                    {!participant.isLocal &&
                        <DailyAudio sessionId={session_id} />
                    }
                    {participant.data &&
                        <div className={styles.name}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.externalUser?.id}`}`}</div>
                    }
                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            }
            {participant && screens.find(s => s.session_id === session_id) &&
                <div className={styles.container} style={style} onClick={() => {
                    onClick(true, false, false)
                }}>
                    <DailyVideo automirror={participant.isLocal} sessionId={session_id} type="screenVideo" style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                    <DailyAudio sessionId={session_id} />

                    <div className={styles.name}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.externalUser?.id}`}`}</div>

                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            }
            {participant && participant.whiteboard &&
                <div className={styles.container} style={style} onClick={() => {
                    onClick(false, true, false)
                }}>
                    <video autoPlay ref={streamRef} style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%', background: 'white' }} />
                    <DailyAudio sessionId={session_id} />

                    <div className={styles.name}>{participant.isLocal ? "Lavagna" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.externalUser?.id}`}`}</div>

                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            }
            {participant && participant.presentation &&
                <div className={styles.container} style={style} onClick={() => {
                    onClick(false, false, true)
                }}>
                    <video autoPlay ref={streamRef} style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%', background: 'white' }} />
                    <DailyAudio sessionId={session_id} />

                    <div className={styles.name}>{participant.isLocal ? "Presentazione" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.externalUser?.id}`}`}</div>

                    <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                        {participant.audio ? <MicIcon /> : <MicOffIcon />}
                    </div>
                </div>
            }

        </>
    )

}

export default DailyParticipant