import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { Each } from "../common/Each"
import CourseCard from "../components/cards/CourseCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./ShopCourses.module.css"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Button from "../components/Button"
import AutoplayVerticalCarousel from "../components/AutoplayVerticalCarousel"

const ShopCourses = () => {

    const { t } = useTranslation()
    const [courses, setCourses] = useState(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        async function getCourses() {
            try {
                let courses = await api.get("/courses")
                setCourses(courses)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!courses) {
            getCourses()
        }
    })

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Corsi</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                {courses && courses.length > 0 &&
                    <>
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-color)', paddingTop: 0, paddingBottom: 0 }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.banner}>
                                    <div className={styles.bannerLeft}>
                                        <div className={typo.title}>
                                            Scopri i nostri corsi
                                        </div>
                                        <div className={typo.body} style={{ paddingBottom: '2rem' }}>
                                            Percorsi di carriera verticali per sviluppare hard e soft skills fondamentali nel mondo del lavoro.
                                        </div>
                                        <Button accentColor={'var(--tertiary)'} style={{ padding: '.7rem 1rem', fontSize: '1.125rem' }}>SCOPRI I CORSI</Button>
                                    </div>
                                    <div className={styles.bannerRight}>
                                        <AutoplayVerticalCarousel
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{objectFit: 'cover'}}
                                        />
                                        <AutoplayVerticalCarousel
                                            reverse
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{objectFit: 'cover'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.section} style={{background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,178,255,0.12) 20%, rgba(255,255,255,0) 100%)'}}>
                            <div className={styles.sectionInner}>
                                <div className={styles.sectionTitle}>
                                    <div className={typo.subtitle}>{t('courses.suggested')}</div>
                                    {/* <Button appearance="text">VEDI TUTTI</Button> */}
                                </div>
                                <div className={styles.courses}>
                                    {courses &&
                                        <Each of={courses} render={(c, index) => {
                                            return (
                                                <div className={styles.courseCardWrapper}>
                                                    <CourseCard course={c}></CourseCard>
                                                </div>
                                            )
                                        }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </HeaderFooterLayout>
    )
}

export default ShopCourses