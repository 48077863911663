import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import MainContext from "../../common/MainContext"
import styles from "./PaymentDialog.module.css"
import typo from "../../typography.module.css"
import Loader from "../Loader"
import Button from "../Button"
import { formatPrice } from "../../utils"
import Succeeded from "../animated/Succeeded"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg";
import api from "../../api"

const StripePaymentElement = ({orderNo, amount, showAmountConfirm = true}) => {

    const context = useContext(MainContext)
    const [errorMessage, setErrorMessage] = useState(null)
    const [succeeded, setSucceeded] = useState(false)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()

    const handleSubmitPayment = async () => {
        setLoading(true)
        setErrorMessage(null)
        if (!stripe || !elements) {
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `http://beta.startingfinance.com:3000/orders/${orderNo}`,
            },
            redirect: 'if_required',
        });

        if (error) {
            console.error(error)
            setErrorMessage(error.message);
        } else {
            if (paymentIntent.status === 'succeeded') {
                setSucceeded(true)
                clearCart()
            }
        }
        setLoading(false)
    }

    const clearCart = async() => {
        var newCart = structuredClone(context.cart)
        newCart.products = []
        context.setCart(newCart)
        
        try{
            let cart = await api.put("/cart/clear")
            context.setCart(cart)
        }
        catch(e){
            console.error(e)
        }
    }

    return (
        <>

            {!succeeded &&
                <div className={styles.stripeElement}>
                    <PaymentElement options={{ layout: {type: 'tabs'} }}/>
                    {errorMessage && <div className={styles.paymentError}>{errorMessage}</div>}

                    {loading &&
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '1rem 0' }}>
                            <Loader />
                            <div className={typo.headline}>Processando il pagamento...</div>
                        </div>
                    }
                    {!loading &&
                        <Button
                            disabled={loading}
                            style={{ fontSize: '1rem', padding: '.5rem 2rem', marginTop: '1rem' }}
                            onClick={handleSubmitPayment}>
                            {showAmountConfirm ? `CONFERMA €${formatPrice(amount/100)}`.toUpperCase() : 'CONFERMA E PAGA'}
                        </Button>
                    }
                </div>
            }
            {succeeded &&
                <>
                    <Succeeded />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                        <div className={typo.subtitle}>Pagamento Completato</div>
                        <div className={typo.body} style={{ opacity: 0.88, textAlign: 'center' }}>
                            Grazie per aver acquistato su Starting Finance, troverai il tuo corso o la tua masterclass nella sezione "Il mio percorso".
                        </div>
                        <Button 
                        onClick={() => { 
                            context.setCheckout({open: false, order: null})
                            navigate("/my-path")
                        }} 
                        inverse>
                            VAI AL MIO PERCORSO 
                            <ArrowIcon className={styles.arrow} />
                        </Button>
                    </div>
                </>
            }
        </>

    )
}

export default StripePaymentElement