import { useEffect } from "react"
import styles from "./Skeleton.module.css"

const Skeleton = ({ type = "rect", width, height, borderRadius = 0 }) => {

    return (
        <>
            {type === "circle" &&
                <div className={styles.circle} style={{ minHeight: height, minWidth: width, maxHeight: height, maxWidth: width, borderRadius: width }}>
                </div>
            }
            {type === "rect" &&
                <div className={styles.rect} style={{ height: height, width: width, borderRadius: borderRadius }}>
                </div>
            }
        </>

    )

}

export default Skeleton
