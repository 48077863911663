import { isToday, isTomorrow, isYesterday } from "date-fns";
import { palette, darkPalette } from "./common/constants"

export function capitalize(string) {
    // Verifica se la stringa è vuota
    if (!string) {
        return "";
    }

    // Dividi la stringa in un array di parole
    const words = string.split(' ');

    // Capitalizza ogni parola nell'array
    const capitalizedWords = words.map(word => {
        // Capitalizza la prima lettera di ogni parola
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Unisci le parole capitalizzate in una stringa e restituiscila
    return capitalizedWords.join(' ');
}

export function filterArray(arr, str) {
    return arr.filter(item => {
        const words = str
            .toLowerCase()
            .split(' ')
            .filter(word => word.length > 2); // Dividi la stringa in parole lunghe più di 3 lettere
        return words.every(word => item.toLowerCase().includes(word)); // Controlla se tutte le parole sono incluse nella stringa
    });
}

export function sortFilteredArray(arr, str) {
    return arr.sort((a, b) => {
        const startsWithStrA = a.toLowerCase().startsWith(str.toLowerCase());
        const startsWithStrB = b.toLowerCase().startsWith(str.toLowerCase());

        // Se entrambi iniziano con la stringa specificata, li ordina normalmente
        if (startsWithStrA && startsWithStrB) {
            return a.localeCompare(b);
        }
        // Se solo uno inizia con la stringa, lo mette prima
        else if (startsWithStrA) {
            return -1;
        } else if (startsWithStrB) {
            return 1;
        }
        // Altrimenti, li ordina normalmente
        else {
            return a.localeCompare(b);
        }
    });
}

// Funzione principale
export function filterAndSortArray(arr, str) {
    const filteredArr = filterArray(arr, str);
    return sortFilteredArray(filteredArr, str);
}

export function formatPrice(num) {
    if (!num) {
        return "00,00"
    }
    // Converti il numero in una stringa e sostituisci il punto decimale con la virgola
    let formattedNum = num.toString().replace('.', ',');

    // Se il numero contiene una virgola
    if (formattedNum.includes(',')) {
        // Dividi la parte intera dalla parte decimale
        let parts = formattedNum.split(',');

        // Formatta la parte intera aggiungendo i punti come separatore ogni 3 cifre
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Unisci le due parti e restituisci il risultato
        return parts.join(',');
    } else {
        // Se non ci sono decimali, aggiungi ",00" alla fine
        formattedNum += ',00';
    }

    // Se la parte intera ha più di tre cifre, aggiungi i punti come separatore ogni 3 cifre
    if (formattedNum.length > 3) {
        formattedNum = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return formattedNum;
}

export function splitArray(array, nParts) {
    var subArrays = []
    for (let i = 0; i < nParts; i++) {
        subArrays.push([])
    }
    for (let j = 0; j < array.length; j++) {
        const index = j % subArrays.length; // Calcola l'indice del colore utilizzando l'operatore modulo
        subArrays[index].push(array[j])
    }

    return subArrays
}

export function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

export function hexAlpha(color, alpha) {
    // Converti la percentuale alpha in un valore compreso tra 0 e 1
    const _alpha = alpha / 100;

    // Calcola il valore alpha in esadecimale
    const alphaHex = Math.round(_alpha * 255).toString(16).toUpperCase();

    // Assicurati che il valore esadecimale abbia due cifre
    const alphaPadded = alphaHex.padStart(2, '0');

    // Restituisci il colore esadecimale con alpha
    return color + alphaPadded;
}

export function formatDate(dateString, compact = false, includeWeekDay = true) {
    let date = new Date(dateString)
    // Array dei nomi dei giorni della settimana
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

    // Array dei nomi dei mesi
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

    // Ottieni il giorno della settimana, il giorno del mese e il mese dalla data
    const dayOfWeek = compact ? daysOfWeek[date.getDay()].substring(0, 3) : daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = compact ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];

    if (isToday(date)) {
        return 'Oggi'
    }
    else if (isTomorrow(date)) {
        return 'Domani'
    }
    else if (isYesterday(date)) {
        return 'Ieri'
    }

    // Formatta la data nel formato desiderato
    const formattedDate = includeWeekDay ? `${dayOfWeek} ${dayOfMonth} ${month}` : `${dayOfMonth} ${month}`

    return formattedDate;
}

export function formatDateV2(timestamp, options, lng = "it", applyTz = true) {
    const date = new Date(timestamp)
    let userDate = date

    if (applyTz) {
        const offset = date.getTimezoneOffset()
        userDate = new Date(date.getTime() - (offset * 60 * 1000));
    }

    if (isToday(userDate)) {
        return 'Oggi'
    }
    else if (isTomorrow(userDate)) {
        return 'Domani'
    }
    else if (isYesterday(userDate)) {
        return 'Ieri'
    }

    var baseOptions = { month: 'long', day: 'numeric' };
    baseOptions = options ? options : baseOptions
    return userDate.toLocaleDateString(lng, baseOptions)
}

export function formatTimeV2(timestamp, options, lng = "it", applyTz = true) {
    const date = new Date(timestamp)
    var userDate = date
    if (applyTz) {
        const offset = date.getTimezoneOffset()
        userDate = new Date(date.getTime() - (offset * 60 * 1000))
    }
    var baseOptions = { hour: '2-digit', minute: '2-digit' };
    baseOptions = options ? options : baseOptions
    return userDate.toLocaleTimeString(lng, baseOptions)
}

export function formatTime(dateString) {
    let date = new Date(dateString)
    // Ottieni le ore e i minuti dalla data
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Aggiungi uno zero iniziale se i minuti sono inferiore a 10
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Formatta l'ora nel formato HH:mm
    const formattedTime = `${hours}:${formattedMinutes}`;

    return formattedTime;
}

export function getMonthName(dateString, compact = false) {
    const date = new Date(dateString)
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    return compact ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];
}

export function getDayName(dateString, compact = false) {
    const date = new Date(dateString)
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    return compact ? daysOfWeek[date.getDay()].substring(0, 3) : daysOfWeek[date.getDay()];
}

export function hourDiff(dateString1, dateString2) {
    // Converti le stringhe in oggetti Date
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    // Calcola la differenza in millisecondi
    const msDiff = Math.abs(date1 - date2);

    // Converti la differenza in ore
    const hDiff = Math.floor(msDiff / (1000 * 60 * 60));

    return hDiff;
}

export function calcLastUpdate(dateString, compact, tz = false) {
    let now = new Date();
    let date = new Date(dateString);

    if (tz) {
        let offset = date.getTimezoneOffset();

        date = new Date(date.getTime() - (offset * 60 * 1000));
    }


    const msDiff = Math.abs(now - date);

    const sDiff = parseInt(msDiff / (1000))
    const mDiff = parseInt(msDiff / (1000 * 60))
    const hDiff = parseInt(msDiff / (1000 * 60 * 60))
    const dDiff = parseInt(msDiff / (1000 * 60 * 60 * 24))
    const wDiff = parseInt(msDiff / (1000 * 60 * 60 * 24 * 7))

    var result = compact ? `${wDiff}s` : `${wDiff} ${wDiff === 1 ? 'settimana' : 'settimane'} fa`

    if (wDiff < 1) {
        result = compact ? `${dDiff}g` : `${dDiff} ${dDiff === 1 ? 'giorno' : 'giorni'} fa`
        if (hDiff < 24) {
            result = compact ? `${hDiff}h` : `${hDiff} ${hDiff === 1 ? 'ora' : 'ore'} fa`
            if (hDiff < 1) {
                result = compact ? `${mDiff}m` : `${mDiff} ${mDiff === 1 ? 'minuto' : 'minuti'} fa`
                if (mDiff < 1) {
                    result = compact ? `${sDiff}sec` : `${sDiff} ${sDiff === 1 ? 'secondo' : 'secondi'} fa`
                }
            }
        }
    }

    return result;
}

/**
 * This function removes the duplicates from an array checking the id field.
 * @param {*} array 
 * @returns the array with no duplicates objects.
 */
export const dropDuplicates = (array) => {
    const uniqueObjects = {};
    const result = [];

    // Itera sull'array originale
    array.forEach(obj => {
        // Verifica se l'oggetto con lo stesso id è già presente nell'oggetto uniqueObjects
        if (!uniqueObjects[obj.id]) {
            // Se non è presente, aggiungi l'oggetto all'oggetto uniqueObjects e al risultato
            uniqueObjects[obj.id] = true;
            result.push(obj);
        }
    });

    return result;
};

export function formatFileSize(byteSize) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let size = byteSize;
    let i = 0;

    while (size >= 1000 && i < units.length - 1) {
        size /= 1000;
        i++;
    }

    return size.toFixed(2) + ' ' + units[i];
}

export function getLocaleISOString() {
    const currentDate = new Date().toLocaleDateString()
    const currentTime = new Date().toLocaleTimeString()
    const components = currentDate.split('/')
    const isoString = `${components[2]}-${components[1].padStart(2, '0')}-${components[0].padStart(2, '0')}T${currentTime}`
    return isoString
}


export const getInitials = (input) => {
    // Dividi la stringa in parole usando lo spazio come delimitatore
    const words = input.split(' ');

    // Prendi la prima lettera di ciascuna parola e uniscile
    const initials = words.map(word => word.charAt(0)).join('');

    // Restituisci la stringa di iniziali
    return initials.slice(0, 2);
}

export const getRandomColor = (mode = 'light') => {

    var currentPalette = palette
    if (mode === 'dark') {
        currentPalette = darkPalette
    }
    // Calcola un indice casuale compreso tra 0 e la lunghezza dell'array meno 1
    const randomIndex = Math.floor(Math.random() * currentPalette.length);

    // Restituisci l'elemento all'indice casuale
    return currentPalette[randomIndex];
}

export function isTestChoiceValid(selected) {
    // almeno una risposta è stata scelta
    let isValid = false
    for (const { value } of selected) {
        if (value) {
            isValid = value
            break;
        }
    }
    return isValid
}


export function canTestBeSubmitted(userTest, innerTests) {
    for (const t of innerTests) {
        if (!userTest[t.id]) {
            return false
        }
    }
    for (const [id, { isValid }] of Object.entries(userTest)) {
        if (isValid === false) {
            return false
        }
    }
    return true
}

export function findMostFrequent(arr) {
    if (arr.length === 0) return null;

    const frequencyMap = {};

    // Conta la frequenza di ciascun elemento
    for (let item of arr) {
        if (frequencyMap[item]) {
            frequencyMap[item]++;
        } else {
            frequencyMap[item] = 1;
        }
    }

    // Trova l'elemento con la maggiore frequenza
    let mostFrequentItem = arr[0];
    let maxCount = 1;

    for (let item in frequencyMap) {
        if (frequencyMap[item] > maxCount) {
            mostFrequentItem = item;
            maxCount = frequencyMap[item];
        }
    }

    return mostFrequentItem;
}

export function getLocalDate(noTimezoneDate) {
    let date = new Date(noTimezoneDate)
    const offset = date.getTimezoneOffset()
    return new Date(date.getTime() - (offset * 60 * 1000))
}
