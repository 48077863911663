import axios from "axios";
import { auth } from "./firebase"
import { signInAnonymously } from "firebase/auth";

const api = axios.create({
  //baseURL: "http://localhost:8000",
  baseURL: "https://beta.startingfinance.com/api",
  timeout: "30000"
})


api.interceptors.request.use(async (config) => {
  // Ottieni il token

  if (!auth.currentUser) {
    console.error(`Current User is null during ${config.url}`)
  }

  const tokenResult = await auth.currentUser.getIdTokenResult()
  const token = tokenResult.token

  // Aggiungi il token all'header Authorization
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, (error) => {
  // Gestisci errori nell'interceptor
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  if (![200, 201].includes(response.status)) {
    return Promise.reject(response.data)
  }
  return response.data
}, async (error) => {
  console.debug(auth.currentUser)
  if (error.response.data.code === 9) {
    console.log("Profile suspended. Force logout")
    await auth.signOut()
    await signInAnonymously(auth)

    if (window.location.pathname !== "/login") {
      window.location.reload()
    }
  }
  return Promise.reject(error.response.data)
})

export default api;
