import { useEffect } from "react"
import styles from "./Radio.module.css"

const Radio = ({ value = false, onChange = null, shape = "default", error = false }) => {
  return (
    <div className={styles.wrapper} onClick={(e) => {
      e.stopPropagation()
      e.preventDefault()

      if (onChange) {
        onChange(!value)
      }
    }} style={{
      borderRadius: shape === "rect" ? "6px" : "20px",
      "--accent-color": error ? "var(--secondary)" : "var(--primary)",
      cursor: onChange ? "pointer" : "default"
    }}>
      <div className={value ? `${styles.selected} ${styles.visible}` : styles.selected} style={{ borderRadius: shape === "rect" ? "4px" : "20px" }} />
    </div>
  )
}

export default Radio 
