import styles from "./MacroSection.module.css"
import { Each } from "../common/Each"
import typo from "../typography.module.css"
import { MDXEditor } from '@mdxeditor/editor'
import ReactPlayer from "react-player"
import useWindowDimensions from "../common/hooks/useWindowDimensions"

const MacroSection = ({ section, showPositions = true }) => {

    const { width } = useWindowDimensions()

    return (
        <div className={styles.container} id={section.id}>
            <div className={typo.subtitle}>{showPositions ? `${section.position}. ` : ''}{section.name}</div>
            <Each of={section.rows} render={(row) => {
                return (
                    <div className={styles.row} style={row.style}>
                        {row.type === 'text' &&
                            <div className={`${typo.body} ${styles.text}`}>
                                <MDXEditor markdown={row.content} readOnly />
                            </div>
                        }
                        {row.type === 'columns' &&
                            <Each of={row.content} render={(column) => {
                                return (
                                    <div className={styles.column} style={{ width: `${100 / row.content.length}%`, ...column.style }}>
                                        {column.type === 'text' &&
                                            <div className={`${typo.body} ${styles.text}`}>
                                                <MDXEditor markdown={column.content} readOnly />
                                            </div>
                                        }
                                        {column.type === 'image' &&
                                            <img src={column.content} alt="" />
                                        }
                                        {column.type === 'video' &&
                                            <div className={styles.playerWrapper}>
                                                <ReactPlayer
                                                    url={column.content}
                                                    width="100%"
                                                    height="100%"
                                                    className={styles.player}
                                                />
                                            </div>
                                        }
                                    </div>
                                )
                            }} />
                        }
                    </div>
                )
            }} />
        </div>
    )

}

export default MacroSection
