import { motion } from "framer-motion"

const Clock = ({className}) => {

    return (
        <svg className={className} width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <motion.g 
            initial={{transform: 'rotate(0)'}}
            animate={{transform: 'rotate(360deg)'}}
            transition={{duration: 12, repeat: Infinity, ease: 'linear'}}
            id="Gruppo-Ore">
                <path id="Percorso" fill="none" stroke="#000000" stroke-width="3.50001" stroke-linecap="round" stroke-linejoin="round" d="M 24.000149 45 C 35.598 45 45 35.598 45 24 C 45 12.402031 35.598 3 24.000149 3 C 12.40215 3 3.00015 12.402031 3.00015 24 C 3.00015 35.598 12.40215 45 24.000149 45 Z" />
                <path id="Linea-copia" fill="#000000" fill-rule="evenodd" stroke="#000000" stroke-width="4" stroke-linecap="round" d="M 33 17 L 24.100328 24.130753" />
            </motion.g>
            <motion.g 
            initial={{transform: 'rotate(0)'}}
            animate={{transform: 'rotate(360deg)'}}
            transition={{duration: 2, repeat: Infinity, ease:'linear'}}
            id="Gruppo-Minuti">
                <path id="path1" fill="none" stroke="none" d="M 24.000149 45 C 35.598 45 45 35.598 45 24 C 45 12.402031 35.598 3 24.000149 3 C 12.40215 3 3.00015 12.402031 3.00015 24 C 3.00015 35.598 12.40215 45 24.000149 45 Z" />
                <path id="Linea" fill="#000000" fill-rule="evenodd" stroke="#000000" stroke-width="4" stroke-linecap="round" d="M 24 9 L 24 24" />
            </motion.g>
        </svg>
    )
}

export default Clock