import ReactPlayer from "react-player"
import styles from "./VideoCard.module.css"
import typo from "../../typography.module.css"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import { useNavigate } from "react-router-dom"

const VideoCard = ({ video }) => {

    const navigate = useNavigate();

    return (
        <div className={styles.container} onClick={() => {
            window.location.href = `/videos/${video.slug}`
        }}>
            <div className={styles.playerWrapper}>
                <ReactPlayer
                    config={{
                        youtube: {
                            playerVars: {
                                controls: 0,
                                showinfo: 0,
                                modestbranding: 1,
                                fs: 0,
                                autoplay: 0,
                                rel: 0
                            }
                        }
                    }}
                    url={video.url}
                    controls={false}
                    muted={true}
                    className={styles.player}
                    width="100%"
                    height="100%"
                />
                <div className={styles.playerOverlay} onClick={(e) => {
                    window.location.href = `/videos/${video.slug}`
                }} />
            </div>
            <div className={styles.info}>
                <div className={typo.subtitle} style={{ fontSize: '1.125rem' }}>{video.title}</div>
                <div className={styles.tags}>
                    <Each of={video.tags} render={(t) => {
                        return (
                            <Tag tag={t} />
                        )
                    }} />
                </div>
            </div>
        </div>
    )

}

export default VideoCard