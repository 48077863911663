import { useEffect, useState } from "react";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./MacroDetail.module.css"
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Each } from "../common/Each";
import MacroSection from "../components/MacroSection";
import Button from "../components/Button";
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import ReactPlayer from "react-player";
import typo from "../typography.module.css"

const MacroDetail = () => {

    const { slug } = useParams();
    const navigate = useNavigate()
    const [macro, setMacro] = useState(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        const getBySlug = async () => {
            try {
                let macro = await api.get(`/macros/slug/${slug}`)
                setMacro(macro)
            }
            catch {
                // navigate("/404")
            }
        }
        getBySlug()
    }, [slug])

    const moveToElement = (id) => {
        const elem = document.getElementById(id);
        if (elem) {
            let position = elem.getBoundingClientRect();
            window.scrollTo({top: position.top - 80, behavior: 'smooth'}); 
        }
    }

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                {macro &&
                    <>
                        <div className={styles.section} style={{ paddingTop: '1rem', borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                            <div className={[styles.sectionInner, styles.back].join(' ')}>
                                <Button appearance="text" style={{ display: 'flex', alignItems: 'center', gap: '0.3rem', paddingBottom: '2rem' }}
                                    onClick={() => { navigate(-1) }}>
                                    <ArrowIcon style={{ width: '12px', height: '12px', minWidth: '12px', minHeight: '12px' }} />
                                    Torna indietro
                                </Button>
                            </div>
                            <div className={styles.sectionInner}>
                                <div className={styles.main}>
                                    <div className={styles.macroLeft}>
                                        <div className={typo.subtitle}>{macro.name}</div>
                                        <Each of={macro.content} render={(section, index) => {
                                            return (
                                                <button
                                                    onClick={() => {
                                                        moveToElement(section.id)
                                                    }}
                                                    className={styles.sectionButton}>{index + 1}. {section.name}</button>
                                            )
                                        }} />
                                    </div>
                                    <div className={styles.macroRight}>
                                        <div className={styles.playerWrapper}>
                                            <ReactPlayer
                                                url={macro.video_url}
                                                controls
                                                width="100%"
                                                height="100%"
                                                className={styles.player}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Each of={macro.content} render={(section, index) => {
                            section.position = index + 1
                            return (
                                <div className={styles.section} style={{ borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                                    <div className={styles.sectionInner}>
                                        <MacroSection section={section} />
                                    </div>
                                </div>
                            )
                        }} />
                    </>
                }
            </div>
        </HeaderFooterLayout>
    )

}

export default MacroDetail