import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Each } from "../../common/Each"
import { CorrectionType, TestType } from "../../enums"
import { isTestChoiceValid } from "../../utils"
import styles from "./TestTrueFalse.module.css"
import TrueFalseBadge from "./TrueFalseBadge"

const TestTrueFalse = ({
    id = null,
    answers = [],
    text = "",
    testType = null,
    onSelect = () => { },
    responses = null,
    correctionType = null // se presente si mostrano i risultati    
}) => {

    const { t } = useTranslation()
    const [userAnswers, setUserAnswers] = useState(answers.map(a => {
        return {
            id: a.id,
            value: null
        }
    }))

    const type = useMemo(() => {
        if (!testType)
            return null

        switch (testType) {
            case TestType.SingleChoice: return "singleChoice"
            case TestType.MultipleChoice: return "multipleChoice"
            case TestType.TrueFalse: return "trueFalse"
            case TestType.Matching: return "matching"
            case TestType.TextCompletion: return "textCompletion"
            default: console.error("invalid test type"); break
        }
    }, [testType])

    useEffect(() => {
        if (responses && !correctionType) {
            setUserAnswers([...responses])
        }
    }, [answers])

    useEffect(() => {
        const isValid = isTestChoiceValid(userAnswers)
        onSelect(id, testType, isValid, userAnswers)
    }, [userAnswers])

    const onAnswerChange = useCallback((index, value) => {
        setUserAnswers((p) => {
            p[index].value = value
            return [...p]
        })
    }, [userAnswers, answers])

    return (
        <>
            {
                correctionType === null &&
                <div className={styles.container}>
                    <div className={styles.text}>{t(`tests.testType.${type}`)}</div>
                    <Each
                        of={answers}
                        render={(answer, index) => (
                            <div className={userAnswers.find(ua => ua.id === answer.id)?.value ? `${styles.answer} ${styles.userAnswers}` : styles.answer}>
                                {answer.text}
                                <div className={styles.badges}>
                                    <TrueFalseBadge type={false} value={userAnswers.find(ua => ua.id === answer.id)?.value} onChange={(value) => onAnswerChange(index, value)} />
                                    <TrueFalseBadge type={true} value={userAnswers.find(ua => ua.id === answer.id)?.value} onChange={(value) => onAnswerChange(index, value)} />
                                </div>
                            </div>
                        )}
                    />
                </div>
            }

            {
                correctionType &&
                <div className={styles.container}>
                    <div className={styles.text}>{t(`tests.testType.${type}`)}</div>
                    <Each
                        of={answers}
                        render={(answer, index) => {
                            let userResponse = null
                            let className = styles.answerSolution
                            if (responses.responses) {
                                const ur = responses.responses.find(r => r.id === answer.id)
                                if (ur) {
                                    userResponse = ur
                                    if (answer.value !== userResponse.value) {
                                        className = `${styles.answerSolution} ${styles.wrong}`
                                    }
                                }
                            }

                            return (<div className={className}>
                                {answer.text}
                                <div className={styles.badges}>
                                    <TrueFalseBadge type={false} value={userResponse.value} />
                                    <TrueFalseBadge type={true} value={userResponse.value} />
                                </div>
                            </div>)
                        }}
                    />
                </div>
            }
        </>
    )
}


export default TestTrueFalse
