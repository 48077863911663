import { useDaily, useDailyEvent, useDevices, useMediaTrack, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as ChatIconOff } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ScreenIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg";
import { ReactComponent as HandIcon } from "../../assets/images/icons/ic-hand.svg"
import { Each } from "../../common/Each";
import useSpeechDetection from "../../common/hooks/useSpeechDetection";
import DailyControl from "./DailyControl";
import styles from "./DailyControls.module.css";
import DailySpinner from "./DailySpinner";
const DailyControls = ({ localSessionId, showParticipants, fullScreen, chat }) => {

    const call = useDaily()
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack'])
    const devices = useDevices()
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare()
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)
    const audioLevel = useSpeechDetection(audioTrack.track)

    const [handRaised, setHandRaised] = useState(null)
    const [accessState, setAccessState] = useState(null)

    const onAccessStateUpdate = async(ev) => {
        setAccessState(ev.access.level)
    }
    
    useDailyEvent('access-state-updated', onAccessStateUpdate)

    useEffect(() => {
        setHandRaised(localParticipant?.[3]?.handRaised ?? false)
    }, [localParticipant])

    return (
        <div className={styles.container}>
            <DailyControl
                id="microphones"
                active={localParticipant[0]}
                icons={{
                    normal: <MicOffIcon className={styles.toolIcon} />,
                    active: <MicIcon className={styles.toolIcon} />
                }}
                onClick={() => {
                    call.setLocalAudio(!localParticipant[0])
                }}
                hasOption={devices.microphones.length > 1}
                level={audioLevel}
            >
                <div className={styles.devices}>
                    <Each of={devices.microphones} render={(microphone) => (
                        <div className={styles.device} onClick={async (e) => {
                            e.stopPropagation()
                            setLoadingMic(microphone.device.deviceId)
                            await devices.setMicrophone(microphone.device.deviceId)
                            setLoadingMic(null)
                        }}>
                            <div className={styles.deviceSelected}>
                                {microphone.selected &&
                                    <CheckIcon style={{ width: '12px', height: '12px' }} />
                                }
                                {loadingMic === microphone.device.deviceId &&
                                    <DailySpinner />
                                }
                            </div>
                            <div className={styles.deviceLabel}>
                                {microphone.device.label}
                            </div>
                        </div>
                    )} />
                </div>

            </DailyControl>

            <DailyControl
                id="cameras"
                active={localParticipant[1]}
                icons={{
                    normal: <CameraOffIcon className={styles.toolIcon} />,
                    active: <CameraIcon className={styles.toolIcon} />
                }}
                onClick={() => {
                    call.setLocalVideo(!localParticipant[1])
                }}
                hasOption={devices.cameras.length > 1}
            >
                <div className={styles.devices}>
                    <Each of={devices.cameras} render={(camera) => (
                        <div className={styles.device} onClick={async (e) => {
                            e.stopPropagation()
                            setLoadingCam(camera.device.deviceId)
                            await devices.setCamera(camera.device.deviceId)
                            setLoadingCam(null)
                            // setDevicesDropdown(null)
                        }}>
                            <div className={styles.deviceSelected}>
                                {camera.selected &&
                                    <CheckIcon style={{ width: '12px', height: '12px' }} />
                                }
                                {loadingCam === camera.device.deviceId &&
                                    <DailySpinner />
                                }
                            </div>
                            <div className={styles.deviceLabel}>
                                {camera.device.label}
                            </div>
                        </div>
                    )} />
                </div>

            </DailyControl>

            {accessState === 'full' &&
                <>
                    <DailyControl
                        active={showParticipants.state}
                        icons={{
                            normal: <UsersIcon className={`${styles.toolIcon}`} />,
                            active: <UsersIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            showParticipants.setState(!showParticipants.state)
                        }}
                        tooltip={'Utenti'}
                    />

                    <DailyControl
                        active={chat.state}
                        icons={{
                            normal: <ChatIconOff className={`${styles.toolIcon}`} />,
                            active: <ChatIconOff className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            if (!chat.state) {
                                chat.setUnread(0)
                            }
                            chat.setState(!chat.state)
                        }}
                        tooltip="Chat"
                        badgeNumber={chat.unread}
                    />

                    <DailyControl
                        active={handRaised}
                        icons={{
                            normal: <HandIcon className={`${styles.toolIcon}`} />,
                            active: <HandIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            call.setUserData({ ...localParticipant[3], handRaised: !handRaised })
                            setHandRaised(prev => !prev)
                        }}
                        tooltip={handRaised ? "Abbassa la mano" : "Alza la mano"}
                    />

                    <DailyControl
                        active={isSharingScreen}
                        icons={{
                            normal: <ScreenIcon className={`${styles.toolIcon}`} />,
                            active: <ScreenIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            isSharingScreen ? stopScreenShare() : startScreenShare()
                        }}
                        tooltip="Condividi schermo"
                    />

                    <DailyControl
                        active={fullScreen.state}
                        icons={{
                            normal: <FullScreenIcon className={`${styles.toolIcon}`} />,
                            active: <FullScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            if (fullScreen.state) {
                                fullScreen.handle.exit()
                            }
                            else {
                                fullScreen.handle.enter()
                            }
                            fullScreen.setState(!fullScreen.state)
                        }}
                        tooltip={fullScreen.state ? "Riduci" : "Espandi"}
                    />
                </>
            }
        </div>

    )

}

export default DailyControls