import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./MyPath.module.css"
import typo from "../typography.module.css"
import Card from "../components/cards/Card"
import { useEffect, useState } from "react"
import api from "../api"
import { Each } from "../common/Each"
import LessonCard from "../components/cards/LessonCard"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { chunkArray } from "../utils"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { AnimatePresence, motion } from "framer-motion"
import MultiStateSwitch from "../components/MultiStateSwitch"
import Button from "../components/Button"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Loader from "../components/Loader"
import PathCourseCard from "../components/cards/PathCourseCard"
import CourseCard from "../components/cards/CourseCard"
import { CourseStatus, CourseType } from "../common/constants"
import { Helmet, HelmetProvider } from "react-helmet-async"


const MyPath = () => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const [lessons, setLessons] = useState([])
    const [splittedLessons, setSplittedLessons] = useState([])
    const [lessonPage, setLessonPage] = useState(0)
    const [direction, setDirection] = useState({ direction: null, value: 0 })
    const [loading, setLoading] = useState(false)
    const [coursesLoading, setCoursesLoading] = useState(false)

    const [courses, setCourses] = useState([])
    const [filterCourses, setFilterCourses] = useState([])

    const [professionalCourse, setProfessionalCourse] = useState(null)
    const [filterState, setFilterState] = useState(0)

    useEffect(() => {

        window.scrollTo({
            top: 0,
        })

        const getLessons = async () => {
            setLoading(true)
            try {
                let lessons = await api.get("/user/lessons")
                setLessons(lessons)
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        const getCourses = async () => {
            setCoursesLoading(true)
            try {
                let courses = await api.get("/user/courses")
                setCourses(courses)
                setFilterCourses(courses)
                let professional = courses.find(c => c.type === CourseType.Professional)
                if (professional) {
                    setProfessionalCourse(professional)
                }
            }
            catch (e) {
                console.error(e)
            }
            setCoursesLoading(false)
        }

        getCourses()
        getLessons()
    }, [])

    useEffect(() => {
        let parts = 4;

        // Adjusting parts based on screen width
        if (width < 1440) {
            parts = 3;
        }
        if (width < 1160) {
            parts = 2;
        }
        if (width < 1024) {
            parts = 1;
        }
        if (width < 768) {
            parts = 2;
        }
        if (width < 540) {
            parts = 1;
        }

        let chunks = chunkArray(lessons, parts)
        setDirection({ direction: null, value: 0 })
        setLessonPage(0)
        setSplittedLessons(chunks)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessons, width])

    useEffect(() => {
        const previousPage = () => {
            if (lessonPage === 0) {
                return
            }
            else {
                setLessonPage(lessonPage - 1);
            }
        }

        const nextPage = () => {
            if (lessonPage === splittedLessons.length) {
                return
            }
            else {
                setLessonPage(lessonPage + 1);
            }
        }

        if (direction.direction) {
            if (direction.direction === 'right') {
                nextPage()
            }
            else {
                previousPage()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction])

    const handleCoursesFilterChange = (index) => {
        var filtered = []
        switch (index) {
            case 0:
                setFilterCourses(courses)
                break;
            case 1:
                filtered = courses.filter(c => c.status === CourseStatus.InProgress)
                setFilterCourses(filtered)
                break;
            case 2:
                filtered = courses.filter(c => c.status === CourseStatus.ToStart)
                setFilterCourses(filtered)
                break;
            case 3:
                filtered = courses.filter(c => c.status === CourseStatus.Ended)
                setFilterCourses(filtered)
                break;
            default:
                setFilterCourses(courses)
        }
        setFilterState(index)
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Il mio percorso</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            {t('myPath.title')}
                        </div>
                        {/* LESSONS */}
                        <Card style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <div className={styles.header}>
                                <div className={typo.subtitle} style={{ display: 'flex', fontSize: '1rem' }}>{t('myPath.nextLessons')}</div>
                                {lessons.length > 0 &&
                                    <div className={styles.arrows}>
                                        <button
                                            className={`${styles.arrowButton} ${styles.left}`}
                                            disabled={lessonPage === 0}
                                            onClick={() => {
                                                setDirection(prevState => {
                                                    return { direction: 'left', value: prevState.value - 1 };
                                                })
                                            }}
                                        >
                                            <ChevronIcon />
                                        </button>
                                        <button
                                            className={`${styles.arrowButton} ${styles.right}`}
                                            disabled={lessonPage === splittedLessons.length - 1}
                                            onClick={() => {
                                                setDirection(prevState => {
                                                    return { direction: 'right', value: prevState.value + 1 };
                                                })
                                            }}>
                                            <ChevronIcon />
                                        </button>
                                    </div>
                                }
                            </div>
                            {lessons.length > 0 && !loading &&
                                <div className={styles.lessons}>
                                    <AnimatePresence mode="wait">
                                        <Each key={`lessonPage-${lessonPage}`} of={splittedLessons[lessonPage]} render={(lesson, index) => {
                                            return (
                                                <motion.div key={lesson.id} className={styles.lessonCard}
                                                    initial={{ x: (direction.direction !== null) ? (direction.direction === "right" ? "400%" : "-400%") : '400%' }}
                                                    animate={{ x: 0 }}
                                                    exit={{ x: direction.direction === "right" ? "-400%" : "400%" }}
                                                    transition={{ duration: 0.35, ease: "easeInOut", delay: (direction.direction !== null) ? (direction.direction === "right" ? index * 0.1 : index * -0.1) : index * 0.1 }}
                                                >
                                                    <LessonCard lesson={lesson} />
                                                </motion.div>
                                            )
                                        }} />
                                    </AnimatePresence>
                                </div>
                            }
                            {lessons.length === 0 && !loading &&
                                <div className={styles.noLessons}>
                                    {courses.filter(c => c.status === CourseStatus.ToStart).length > 0 &&
                                        <span><Trans i18nKey="myPath.noLessonsSubscribe"></Trans></span>

                                    }
                                    {courses.filter(c => c.status === CourseStatus.ToStart).length === 0 &&
                                        <>
                                            <span><Trans i18nKey="myPath.noLessonsPurchase"></Trans></span>
                                            <Button
                                                onClick={() => { navigate("/courses") }}
                                                style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem' }} inverse>
                                                {t('myPath.goToCourses').toUpperCase()}
                                                <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                            </Button>
                                        </>
                                    }
                                </div>
                            }
                            {loading &&
                                <div className={styles.loaderContainer}>
                                    <Loader />
                                </div>
                            }
                        </Card>

                        {/* PROFESSIONAL COURSE */}
                        {professionalCourse &&
                            <>
                                <div className={typo.title} style={{ fontSize: '1.25rem' }}>
                                    {t('myPath.professionalCourse')}
                                </div>
                                {width <= 540 &&
                                    <CourseCard course={professionalCourse} cardType="myPath" />
                                }
                                {width > 540 &&
                                    <PathCourseCard course={professionalCourse} />
                                }
                            </>
                        }

                        {/* ALL COURSES */}
                        <div className={typo.title} style={{ fontSize: '1.25rem' }}>
                            {t('myPath.myCourses')}
                        </div>
                        <MultiStateSwitch
                            states={[t('myPath.all').toUpperCase(), t('myPath.inProgress').toUpperCase(), t('myPath.toStart').toUpperCase(), t('myPath.ended').toUpperCase()]}
                            onStateChange={handleCoursesFilterChange}
                        />
                        {coursesLoading &&
                            <div className={styles.loaderContainer}>
                                <Loader />
                            </div>
                        }
                        {!coursesLoading &&
                            <>
                                {filterCourses.length > 0 &&
                                    <Each of={filterCourses} render={(course) => {
                                        return (
                                            <PathCourseCard course={course} />
                                        )
                                    }} />
                                }
                                {(courses.length === 0 || (filterCourses.length === 0 && filterState === 2)) &&
                                    <div className={styles.noCourses}>
                                        <span><Trans i18nKey="myPath.noCourses"></Trans></span>
                                        <Button
                                            onClick={() => { navigate("/courses") }}
                                            style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem', backgroundColor: 'var(--background-secondary-color)' }} inverse>
                                            {t('myPath.goToCourses').toUpperCase()}
                                            <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                        </Button>
                                    </div>
                                }

                                {filterCourses.length === 0 && filterState === 1 &&
                                    <div className={styles.noCourses}>
                                        <span><Trans i18nKey="myPath.noInProgressCourses"></Trans></span>
                                    </div>
                                }

                                {filterCourses.length === 0 && filterState === 3 &&
                                    <div className={styles.noCourses}>
                                        <span><Trans i18nKey="myPath.noEndedCourses"></Trans></span>
                                    </div>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default MyPath