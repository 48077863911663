import { GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import { auth } from "../firebase";
import { ReactComponent as LogoBlack } from '../assets/images/starting-finance.svg';
import { ReactComponent as GoogleLogo } from '../assets/images/google.svg';
import { ReactComponent as AppleLogo } from '../assets/images/apple.svg';
import style from "./Login.module.css"
import typo from "../typography.module.css"
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FooterLayout from "../components/layouts/FooterLayout";
import MainContext from "../common/MainContext";

const Signup = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const context = useContext(MainContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState(null)

    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        if (auth.currentUser) {
            if (!auth.currentUser.isAnonymous) {
                navigate('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser])

    const googleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
            let user = await api.get("/user")
            if(user){
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Google Sign In: ", error)
            setError({ code: -1, message: error.message })
        }
    }

    const appleSignIn = async () => {
        try {
            await signInWithPopup(auth, appleProvider)
            let user = await api.get("/user")
            if(user){
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Apple Sign In: ", error)
            setError({ code: -1, message: error.message })
        }
    }

    const signUp = async () => {
        try {
            if (!checkPasswords()) {
                return
            }
            await createUserWithEmailAndPassword(auth, email, password)
            let user = await api.get("/user")
            if(user){
                context.setUser(user)
                navigate("/")
            }
        }
        catch (error) {
            if (error.message.includes('already-in-use')) {
                setError({ code: -1, message: "Questo utente è già registrato." })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
    }

    const onEmailChange = (value) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setError({ code: -1, message: t('errors.invalidEmail') })
        }
        else {
            setError(null)
        }

    }

    const onPasswordChange = (value) => {
        setPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const onPasswordRepeatChange = (value) => {
        setRepeatPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const validateEmail = (email) => {
        if (email.length > 0) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        }
        else {
            return true
        }
    }

    const checkPasswords = () => {
        return (password.length > 0 && repeatPassword.length > 0) ? password === repeatPassword : true
    }

    const goToLogin = () => {
        navigate('/login')
    }

    return (
        <FooterLayout>
            <div className={style.container}>
                    <>
                        <div className={style.columnLeft}>
                            <div className={style.logoContainer}>
                                <LogoBlack className={style.logo} onClick={() => {navigate("/")}}/>
                            </div>
                            <div className={style.innerLeft}>

                                <div className={style.spacer}></div>
                                <div className={[typo.title, style.title].join(' ')}>{t('signup.title')}</div>
                                <div className={[typo.body, style.body].join(' ')}>{t('signup.subtitle')}</div>

                                <div className={style.formContainer}>
                                    <TextInput type="text" placeholder={t('signup.email')} onKeyUp={onEmailChange} autocomplete="off"></TextInput>
                                    <TextInput type="password" placeholder={t('signup.password')} onKeyUp={onPasswordChange} autocomplete="new-password"></TextInput>
                                    <TextInput type="password" placeholder={t('signup.repeatPassword')} onKeyUp={onPasswordRepeatChange} autocomplete="new-password"></TextInput>

                                    <div className={style.error}>
                                        {error &&
                                            <span className={typo.caption}>
                                                {error.message}
                                            </span>
                                        }
                                    </div>
                                </div>

                                <Button onClick={signUp} style={{padding: '1rem 1.5rem'}} fullWidth>{t('signup.register').toUpperCase()}</Button>

                                <div className={style.textDivider}>
                                    <div className={style.divider}></div>
                                    <div className={style.caption}>{t('signup.or')}</div>
                                    <div className={style.divider}></div>
                                </div>

                                <div className={style.socialLogin}>
                                    <button className={style.googleSignIn} onClick={googleSignIn}>
                                        <GoogleLogo className={style.googleLogo}></GoogleLogo>
                                        {t('signup.signUpWithGoogle')}
                                    </button>
                                    <button className={style.appleSignIn}>
                                        <AppleLogo className={style.appleLogo} onClick={appleSignIn}></AppleLogo>
                                        {t('signup.signUpWithApple')}
                                    </button>
                                </div>
                                <div className={style.spacer}></div>
                                <div className={style.registerContainer}>
                                    {t('signup.haveAccount')}
                                    <Button appearance="text" onClick={goToLogin}>{t('signup.login')}</Button>
                                </div>

                            </div>
                            
                        </div>
                        <div className={style.columnRight}>
                            <div className={style.innerRight}>
                                <div className={style.redCircle}></div>
                                <div className={[style.rightTitle, typo.title].join(' ')}>{t('signup.contentTitle')}</div>
                                <div className={[typo.body, style.rightBody].join(' ')}>{t('signup.contentSubtitle')}</div>
                                <div className={style.mockup}></div>
                            </div>
                        </div>
                    </>
            </div>
        </FooterLayout>
    )
}

export default Signup