import styles from "./MaterialPreview.module.css"
import Button from "./Button"
import { formatFileSize } from "../utils";
import { ReactComponent as ThumbnailPdf } from "../assets/images/illustrations/il-thumbnail-pdf.svg"
import { ReactComponent as ThumbnailPpt } from "../assets/images/illustrations/il-thumbnail-ppt.svg"
import { ReactComponent as ThumbnailVideo } from "../assets/images/illustrations/il-thumbnail-video.svg"
import { ReactComponent as ThumbnailExcel } from "../assets/images/illustrations/il-thumbnail-excel.svg"
import { ReactComponent as ThumbnailText } from "../assets/images/illustrations/il-thumbnail-text.svg"
import { ReactComponent as ThumbnailGeneric } from "../assets/images/illustrations/il-thumbnail-generic.svg"
import { ReactComponent as ThumbnailImage } from "../assets/images/illustrations/il-thumbnail-image.svg"
import { ReactComponent as DownloadIcon } from "../assets/images/icons/ic-download.svg"
import { useMemo } from "react";

const MaterialPreview = ({ material }) => {

    const imagesExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng"]
    const videoExtensions =  ["mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]
    const pptExtensions = [".ppt", ".pptx"]
    const excelExtensions = [".xlsx", ".xls"]
    const textExtensions = [".docx", ".doc"]

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = material.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const Thumbnail = useMemo(() => {
        if (material) {
            const fileExtension = material.url.split(".").slice(-1)[0].toLowerCase()
            if (videoExtensions.includes(fileExtension)) {
                return ThumbnailVideo
            }
            if (imagesExtensions.includes(fileExtension)) {
                return ThumbnailImage
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (excelExtensions.includes(fileExtension)) {
                return ThumbnailExcel
            }
            if (textExtensions.includes(fileExtension)) {
                return ThumbnailText
            }
            if (fileExtension === "pdf") {
                return ThumbnailPdf
            }
        }
        return ThumbnailGeneric
    }, [material])

    return (
        <div className={styles.container}>
            <Thumbnail className={styles.thumbnail} />
            <div className={styles.info}>
                <div className={styles.infoInner}>
                    <div>
                        <div className={styles.title}>{material.name}</div>
                        <div className={styles.dimensions}>{formatFileSize(material.size)}</div>
                    </div>
                    <Button 
                    appearance="text"
                    style={{ padding: '.7rem 1rem' }}
                        onClick={handleDownload}
                    >
                        <DownloadIcon />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MaterialPreview
