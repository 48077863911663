import { Navigate } from 'react-router-dom';
import {auth} from "../firebase"

const ProtectedRoute = ({ component }) => {

    return (
        <>
            {!auth.currentUser.isAnonymous ? component : <Navigate to="/login" />}
        </>
    );
}

export default ProtectedRoute