import Button from "../components/Button"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Landing.module.css"
import { ReactComponent as BookIllustration } from "../assets/images/illustrations/il-book-work.svg"
import { ReactComponent as EduIllustration } from "../assets/images/illustrations/il-education-online.svg"
import { ReactComponent as Wave } from "../assets/images/illustrations/il-wave.svg"
import { ReactComponent as SocialBadge } from "../assets/images/illustrations/il-social.svg"
import { ReactComponent as LinkedinBadge } from "../assets/images/illustrations/il-linkedin-badge.svg"
import { ReactComponent as TrustpilotBadge } from "../assets/images/illustrations/il-trustpilot-badge.svg"
import { ReactComponent as VideoBottomLeft } from "../assets/images/illustrations/il-video-bottom-left.svg"
import { ReactComponent as VideoTopRight } from "../assets/images/illustrations/il-video-top-right.svg"
import { ReactComponent as Marketing } from "../assets/images/illustrations/il-marketing.svg"
import { ReactComponent as Contact } from "../assets/images/illustrations/il-contact.svg"

import typo from "../typography.module.css"
import ReactPlayer from "react-player"
import { useEffect, useLayoutEffect, useState } from "react"
import api from "../api"
import ReviewsCarousel from "../components/ReviewsCarousel"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { Each } from "../common/Each"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

const Landing = () => {

    const { width } = useWindowDimensions()
    const [reviews, setReviews] = useState(null)
    // eslint-disable-next-line no-multi-str
    const backgroundImage = 'radial-gradient(circle at 35% 450px,  #4CB4FF24 5%, transparent 20%),\
                            radial-gradient(circle at 55% 450px,  #4CB4FF24 5%, transparent 20%),\
                            radial-gradient(circle at 100% 250px, rgba(var(--primary-rgb), 0.2) 5%, transparent 10%),\
                            radial-gradient(circle at 100% 750px, rgba(var(--sf-yellow-rgb), 0.2) 2%, transparent 6%)'

    const problems = [
        {
            number: '01',
            name: 'Scarsa educazione alla <strong>conoscenza di se stessi</strong> per scegliere la carriera adatta alla <strong>propria personalità</strong>.',
            solution: 'Abbiamo redatto un <strong>questionario</strong> per aiutarti a scegliere il percorso giusto sulla base <strong>delle tue caratteristiche</strong>.'
        },
        {
            number: '02',
            name: 'Poca attitudine a cercare <strong>un nuovo impiego</strong> anche quando quello attuale <strong>non ci soddisfa</strong>.',
            solution: 'Ti aiuteremo personalmente a cercare il lavoro <strong>dei tuoi sogni</strong> ed essere in grado di scegliere ciò che <strong>più ti piace</strong>.'
        },
        {
            number: '03',
            name: 'Poche opportunità di <strong>orientamento</strong> nel mondo economico e finanziario.',
            solution: 'Ti forniremo tutto il materiale necessario a comprendere il mercato del lavoro, le <strong>opportunità</strong> e le competenze più richieste.'
        },

    ]

    useEffect(() => {
        const getReviews = async () => {
            try {
                let reviews = await api.get(`/reviews`)
                setReviews(reviews)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!reviews) {
            getReviews()
        }
    }, [reviews])


    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [card1Ref, inView1] = useInView({
        triggerOnce: false,
        rootMargin: '0px 0px',
    })
    const [card2Ref, inView2] = useInView({
        triggerOnce: false,
        rootMargin: '0px 0px',
    })
    const [card3Ref, inView3] = useInView({
        triggerOnce: false,
        rootMargin: '0px 0px',
    })

    const cardRefs = [
        {ref: card1Ref, inView: inView1},
        {ref: card2Ref, inView: inView2},
        {ref: card3Ref, inView: inView3},
    ]

    return (
        <HeaderFooterLayout style={{ backgroundImage: backgroundImage }}>
            <div className={styles.container}>
                <div className={styles.section} id={styles.main}>
                    <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                        <div className={styles.banner}>
                            <BookIllustration className={styles.bookIllustration} />
                            <EduIllustration className={styles.eduIllustration} />
                            <div className={styles.title}>
                                <div className={styles.titleRow}>
                                    <div>Conosci</div>
                                    <div className={styles.underlined}>
                                        te stesso,
                                        <Wave className={styles.wave} />
                                    </div>
                                </div>
                                <div className={styles.titleRow}>
                                    <div className={styles.underlined}>
                                        Trova
                                        <Wave className={styles.wave} style={{ clipPath: 'polygon(0 0, 58% 0%, 64% 100%, 0 100%)' }} />
                                    </div>
                                    <div>il Tuo</div>
                                    <div className={styles.underlined}>
                                        Lavoro
                                        <Wave className={styles.wave} style={{ clipPath: 'polygon(0 0, 71% 0%, 79% 100%, 0 100%)' }} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.subtitle}>
                                Trova <strong>la tua strada</strong> nel mondo dell'economia e della finanza, un percorso <strong>personalizzato</strong> sulle tue caratteristiche e sul <strong>lavoro dei tuoi sogni</strong>.<br />Fai subito il questionario!
                            </div>
                            <Button additionalClass={styles.actionButton}>INIZIA ORA</Button>
                        </div>
                        <div className={styles.socialProof}>
                            <div className={styles.socialProofBox}>
                                <SocialBadge />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    +1,9 milioni
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    I membri della nostra community
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <LinkedinBadge />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    Miglior Startup
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    Siamo la miglior startup Linkedin 2023
                                </div>
                            </div>
                            <div className={styles.socialProofBox}>
                                <TrustpilotBadge />
                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>
                                    4.8 su 5.0
                                </div>
                                <div className={typo.body} style={{ fontSize: '0.875rem' }}>
                                    La media delle nostre recensioni su Trustpilot
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.section} style={{ background: 'linear-gradient(0deg, var(--background-color) 0%, var(--background-color) 70%, transparent 100%)' }}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={styles.playerWrapper}>
                            <VideoBottomLeft className={styles.videoBottomLeft} />
                            <VideoTopRight className={styles.videoTopRight} />
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dTor1Gd4WSw"
                                width="100%"
                                height="100%"
                                className={styles.player} />
                        </div>
                    </div>
                </div>
                <div className={styles.section} id={styles.problems} style={{ backgroundColor: '#F1F9F7', paddingBottom: 0 }}>
                    <div className={styles.sectionInner} style={{ background: 'transparent', alignItems: 'center' }}>
                        <div className={`${typo.title} ${styles.sectionTitle}`}>
                            Perchè dovresti fare il test ?
                        </div>
                        <div className={typo.body} style={{ textAlign: 'center' }}>
                            In Italia abbiamo <span style={{ color: 'var(--secondary)', fontWeight: 700 }}>tre grossi problemi</span>
                        </div>
                        <div className={styles.parallaxWrapper}>
                            <div className={styles.problems}
                                style={{
                                    paddingBottom: width > 768 ? ((1900 - scrollPosition > 0) ? (1900 - scrollPosition + 32) : 32) : 32
                                }}>

                                <Each of={problems} render={(problem, index) => {
                                    let i = index + 1
                                    return (
                                        <div className={styles.problemWrapper}>
                                            <div className={styles.problem}>
                                                <div className={styles.problemNumber}>{problem.number}</div>
                                                <div dangerouslySetInnerHTML={{ __html: problem.name }} />
                                            </div>
                                            <motion.div
                                                ref={cardRefs[index].ref}
                                                style={{
                                                    top: width > 768 ? ((1800 - scrollPosition > 0) ? (1800 - scrollPosition) : 0) : 0,
                                                }}
                                                initial={{x: width < 768 ? '95%' : 0}}
                                                animate={{x: width < 768 ? ((1500 + (index * 150)) - scrollPosition < 0 ? '0' : '95%') : 0}}
                                                transition={{ duration: 0.5, ease: 'easeInOut'}}
                                                className={width > 768 ? styles.solution : styles.mobileSolution}>
                                                <div className={styles.solutionNumber}>{problem.number}</div>
                                                <div dangerouslySetInnerHTML={{ __html: problem.solution }} />
                                            </motion.div>
                                        </div>
                                    )
                                }} />

                            </div>
                        </div>
                    </div>
                </div>
                {reviews && reviews.length > 0 &&
                    <div className={`${styles.section} ${styles.reviews}`}>
                        <div className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                            <Marketing className={styles.marketingIllustration} />
                            <div className={`${typo.title} ${styles.sectionTitle}`}>
                                Cosa dicono di noi
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center' }}>
                                Le recensioni dei nostri utenti.
                            </div>
                            <div className={styles.reviewsCards}>
                                <ReviewsCarousel reviews={reviews} />
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={styles.contact}>
                            <div className={[typo.title, styles.sectionTitle].join(' ')}>
                                Hai delle domande?
                            </div>
                            <div className={typo.body} style={{ textAlign: 'center', paddingBottom: '2rem' }}>
                                Contattaci al nostro indirizzo email o tramite whatsapp!
                            </div>
                            <Button
                                style={{ padding: '1rem 2rem', fontSize: '1.15rem', zIndex: 1 }}
                                accentColor="var(--tertiary)">
                                CONTATTACI
                            </Button>
                            <Contact className={styles.contactIllustration} />
                        </div>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout >
    )

}

export default Landing