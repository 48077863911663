import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg";
import MainContext from "../../common/MainContext";
import { PaymentStatus, Stripe } from "../../common/constants";
import typo from "../../typography.module.css";
import Button from "../Button";
import Loader from "../Loader";
import Succeeded from "../animated/Succeeded";
import Dialog from "./Dialog";
import styles from "./PaymentDialog.module.css";
import StripePaymentElement from "./StripePaymentElement";

const PaymentDialog = ({ open, onClose, order }) => {

    const context = useContext(MainContext)
    const navigate = useNavigate()
    const stripePromise = loadStripe(Stripe.publicKey)
    const [clientSecret, setClientSecret] = useState('');
    const [succeeded, setSucceeded] = useState(false)
    const [amount, setAmount] = useState(0)

    const fonts = [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800'
        }
    ]

    const appearance = {
        theme: 'stripe',

        variables: {
            colorPrimary: '#2fc6a0',
            colorText: '#262626',
            fontFamily: 'Manrope',
            borderRadius: '12px',
        }
    };

    useEffect(() => {
        const getOrder = async (orderNo) => {
            try{
                let order = await api.get(`/orders/${orderNo}`)
                if(order.status === PaymentStatus.Succeeded){
                    setSucceeded(true)
                }
                else{
                    setClientSecret(order.client_secret)
                    setAmount(order.amount)
                }
            }
            catch(e){
                console.error(e)
            }
        }

        const createOrder = async () => {
            try {
                let order = await api.get("/cart/checkout")
                setClientSecret(order.client_secret)
                setAmount(order.amount)
            }
            catch (e) {
                if (e.code === 5) { //Already Exists
                    setSucceeded(true)
                }
            }
        }
        if (open) {
            if(order){
                getOrder(order)
            }
            else{
                createOrder()
            }
        }
    }, [open, order])

    return (
        <Dialog open={open} title="Completa il pagamento" onClose={onClose}>
            <div className={styles.container}>
                {succeeded &&
                    <>
                        <Succeeded />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                            <div className={typo.subtitle}>Corso già acquistato!</div>
                            <div className={typo.body} style={{ opacity: 0.88, textAlign: 'center' }}>
                            Sembra che tu abbia già acquistato questo corso. Puoi trovarlo nella sezione "il mio percorso".
                            </div>
                            <Button onClick={() => { 
                                context.setCheckout({open: false, order: null})
                                navigate("/my-path") 
                                }} inverse>VAI AL MIO PERCORSO <ArrowIcon className={styles.arrow} /></Button>
                        </div>
                    </>
                }
                {clientSecret && !succeeded &&
                    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, appearance: appearance, fonts: fonts }}>
                        <StripePaymentElement orderNo={order} amount={amount} />
                    </Elements>
                }
                {!clientSecret && !succeeded &&
                    <div style={{ display: 'flex', minHeight: '300px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <Loader />
                        <div className={typo.headline}>Caricando il pagamento...</div>
                    </div>
                }

            </div>
        </Dialog>
    )
}

export default PaymentDialog;
